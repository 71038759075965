/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; font-size:14px; }
body { margin: 0; font-family: "Roboto", sans-serif; }

.layout-topbar {
  background: #3f51b5;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0px;
  color: #fff;
}

.top-menu {
  border: none;
  border-radius: 0;
}

.app-title {
  font-weight: bold;
  color: white;
  font-size: 20px;
  line-height: 25px;
}

.container-fluid {
margin-top: 3px;
max-width: 100%;
padding: 10px;
}

.center-div {
top: 50%;
left: 50%;
width:30em;
height:18em;
margin-top: -9em; /*set to a negative number 1/2 of your height*/
  margin-left: -15em; /*set to a negative number 1/2 of your width*/
  position:fixed;
}

.display-inline-block {
  display: inline-block;
}

/* Rasi Table Design */

#rasi_table {
  width: 100%;
}

#navamsa_table {
  width: 100%;
}

.aln-right {
  float: right;
  text-align: right;
}

.aln-left {
  float: left;
  text-align: left;
}

.colbox {
  width: 100%;
  display: block
}

.singlebox:last-child {
  width: 16.5%!important;
}

.colbox .singlebox {
  width: 16.5%;
  height: 105px;
  min-height: 105px;
  display: block;
  float: left;
  color: #404e67;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  border: 2px solid #3f51b5;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.colbox .doublebox {
  width: 33%;
  height: 105px;
  min-height: 105px;
  display: block;
  float: left;
  color: #404e67;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  border: 2px solid #404e67;
  overflow: hidden;
  position: relative;
}

.notopborder{border-top:none !important;}
.nobottomborder{border-bottom:none !important;}
.noleftborder{border-left:none !important;}
.norightborder{border-right:none !important;}

.noborder-box {
  border: none !important;
  width:16.5%;
  height:105px;
  min-height:105px;
  max-height:105px;
  display: block;
  float: left;
  overflow: hidden;
  position: relative;
}

.dashed-border-box {
  border: 2px dashed #9d9d9d !important;
}

.dashed-top-border{border-top:2px dashed #9d9d9d !important;}
.dashed-bottom-border{border-bottom:2px dashed #9d9d9d !important;}
.dashed-left-border{border-left:2px dashed #9d9d9d !important;}
.dashed-right-border{border-right:2px dashed #9d9d9d !important;}

.transit-border-box {
  background-color: #edf5fd;
}

.show-bavagam-label {
  position: absolute !important;
  bottom: 16px;
  right: 14px;
  background: #cccccc;
  color: #111111;
  font-size: 12px;
}

.colbox .doublebox .heading1 {
  text-align: center;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  left: 25%;
}

.notopborder {
  border-top: none!important
}

.nobottomborder {
  border-bottom: none!important
}

.noleftborder {
  border-left: none!important
}

.norightborder {
  border-right: none!important
}

.empty {
  background: 0 0;
  width: 48.2%;
  text-align: center;
  vertical-align: bottom;
}

.asc-line {
  width: 40px;
  height: 20px;
  border-style: double;
  border-width: 5px;
  -webkit-transform: translateY(20px) translateX(5px) rotate(-26deg);
  position: absolute;
  top: -35px;
  left: -15px;
}

/* End */

.lang-selection {
  display: inline-block;
  line-height: 25px;
  margin-bottom: 7px;
  margin-right: 7px;
}

.p-aln-right {
  text-align: right !important;
}

.table-bordered {
  width: 100%;
  border-spacing: 2px;
  border: 1px solid #e3ebf3;
  border-collapse: collapse;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #e3ebf3;
  padding: 0.5rem 0.5rem !important;
  margin: 0 !important;
}

.planet-positions-list {
  line-height: 18px;
  text-align: left;
  padding: 0;
  margin: 1px;
}

.house-positions-list {
  line-height: 18px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.hp-div {
  cursor: pointer;
  font-weight: 500;
  margin: 5px;
}

.pp-div {
  cursor: pointer;
  font-weight: 500;
  margin: 5px;
  font-size: 12px;
}

.pp-div:hover {
  cursor: pointer;
  color: brown;
}

.overlay-panel-div {
  line-height: 30px;
}

.bg-dark {
  background-color: #3F51B5!important;
}

/* http-global-loader-progress */
.spinner {
  margin: auto;
  font-weight: bold;
  color: black;
  text-align: center;
}

/* loading dots */

.spinner:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;
  }
}

.LoaderWrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .3);
  right: 0;
  bottom: 0;
}

.LoaderWrap .spinnerWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h4, h4 {
  font-size: 1.2rem;
}

.p-radiobutton-box {
  display: inline-flex;
  line-height: 7px;
}

.top-menu .p-menuitem-text, .top-menu .p-menuitem-icon {
  color: #17a2b8 !important;
}

@media screen and (max-width: 960px) {

  .top-menu .p-menuitem-text, .top-menu .p-menuitem-icon {
    color: #3f51b5 !important;
  }

}
